import React from "react";
import phone from "../utilities/vector6.svg";
import chat from "../utilities/vector7.svg";
import mail from "../utilities/vector8.svg";
import web from "../utilities/vector9.svg";


function ContactUs(){
    return(
        <>
        <div className="X">
            <div className="X1">
                <h1>Contact Us</h1>
            </div>

            <div className="X2">

                <div className="x2">
                    <div className="x3">
                        <img src={phone} alt="phone icon" />
                    </div>
                    <div className="x4">
                        <span id="x4a">Call us</span>
                        <span id="x4b">+254 722 611 647</span>
                    </div>
                </div>

                <div className="x2">
                    <div className="x3" id="x3a">
                        <img src={chat} alt="chat icon" />
                    </div>
                    <div className="x4">
                        <span id="x4a">Chat with us</span>
                        <span id="x4b">+254 722 611 647</span>
                    </div>
                </div>

                <div className="x2">
                    <div className="x3" id="x3b">
                        <img src={mail} alt="mail icon" />
                    </div>
                    <div className="x4">
                        <span id="x4a">E-mail</span>
                        <span id="x4b">info@pointone.co.ke</span>
                    </div>
                </div>

                <div className="x2" id="x2a">
                    <div className="x3" id="x3c">
                        <img src={web} alt="web icon" />
                    </div>
                    <div className="x4" >
                        <span id="x4a">Online Order</span>
                        <span id="x4b">www.pointonemedia.co.ke</span>
                    </div>
                </div>
            </div>

            <div className="X3">
                    <div className="x5"><iframe title="Point One Media" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=Dagia%20Plaza,%20Kweria%20Road%20Off,%20Ground%20Floor%20Kirinyaga%20Rd,%20Nairobi+(POINT%20ONE%20MEDIA)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>
                    <div className="x6">
                        <div className="x6a">
                            <span className="x6a1">Point One Media</span> 
                            <span className="x6a2">working hours</span>
                        </div>
                        <div className="x6b"></div>
                        <div className="x6c">
                            <div className="x6c1" id="green"></div>
                            <div className="day" >Monday</div>
                            <div className="time" >8 AM - 7 PM</div>
                        </div>
                        <div className="x6c">
                            <div className="x6c1" id="green"></div>
                            <div className="day" >Tuesday</div>
                            <div className="time" >8 AM - 7 PM</div>
                        </div>
                        <div className="x6c">
                            <div className="x6c1" id="green"></div>
                            <div className="day" >Wednesday</div>
                            <div className="time" >8 AM - 7 PM</div>
                        </div>
                        <div className="x6c">
                            <div className="x6c1" id="green"></div>
                            <div className="day" >Thursday</div>
                            <div className="time" >8 AM - 7 PM</div>
                        </div>
                        <div className="x6c">
                            <div className="x6c1" id="green"></div>
                            <div className="day" >Friday</div>
                            <div className="time" >8 AM - 7 PM</div>
                        </div>
                        <div className="x6c">
                            <div className="x6c1" id="green"></div>
                            <div className="day" >Saturday</div>
                            <div className="time" >9 AM - 6 PM</div>
                        </div>
                        <div className="x6c">
                            <div className="x6c1" id="red"></div>
                            <div className="day">Sunday</div>
                            <div className="time" id="closed" >closed</div>
                        </div>

                    </div>
            </div>

            
        </div>
        </>
    );
};


export default ContactUs;