import React from "react";
import signage from "../utilities/signage.png";
import rollup from "../utilities/rollup.png";
import businessCards from "../utilities/businessCard.png";
import labels from "../utilities/labels.png";
import notebooks from "../utilities/notebooks.png";
import tshirts from "../utilities/tshirts.png";
import wristbands from "../utilities/wristbands.png";



function Category() {
  return (
    <>
      <div className="main">
        <span className="title">Shop Our Top Categories</span>
        <span className="paragraph">
          Our best selling apparel great for any occasion.
        </span>
        <div className="Card">
          <div className="card" id="A">
            <div id="C">
              <span id="c1">Custom Signage</span>
              <span id="c2">
                Our best selling apparel great for any occasion.
              </span>
              <button id="c3">Explore More...</button>
            </div>
            <img src={signage} alt="sinage" />
          </div>
          <div className="card" id="B">
            <div id="C">
              <span id="c1">Roll-up Banners</span>
              <span id="c2">
                Our best selling apparel great for any occasion.
              </span>
              <button id="c3">Explore More...</button>
            </div>
            <img src={rollup} alt="roll-up" />
          </div>
          <div className="Smcard" id="A5">
            <img id="a" src={businessCards} alt="business cards" />
            <button id="D" >Business Cards</button>
          </div>
          <div className="Smcard" id="A1">
            <img id="b" src={tshirts} alt="T-shirts" />
            <button id="D" >T-shirt Branding</button>
          </div>
          <div className="Smcard" id="A2">
            <img id="c" src={labels} alt="Labels" />
            <button id="D" >Product Labels</button>
          </div>
          <div className="Smcard" id="A3">
            <img id="d" src={notebooks} alt="Notebooks" />
            <button id="D" >Notebooks</button>
          </div>
          <div className="Smcard" id="A4">
            <img id="e" src={wristbands} alt="Wristbands" />
            <button id="D" >Wristbands</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
