import React from "react";
import vector1 from "../utilities/vector1.svg"
import vector2 from "../utilities/vector2.svg"
import vector3 from "../utilities/vector3.svg"



function Stats(){
    return(
        <>
            <div className="stat">
                <div className="stats">
                <div className="icon">
                    <div id="icon1" > <img src={vector1} alt="Versatility icon" /></div>
                    <span>Versatility</span>
                </div>
                <div className="icon">
                    <div id="icon2" > <img src={vector2} alt="Quality icon" /></div>
                    <span>Quality</span>
                </div>
                <div className="icon">
                    <div id="icon3"  ><img src={vector3} alt="Affordability icon" /></div>
                    <span>Affordabilty</span>
                </div>
                </div>
            </div>
        </>
    )
};



export default Stats;