import React from "react";
import contact from "../utilities/contact.png";
import phone from "../utilities/vector16.svg";
import email from "../utilities/vector17.svg";
// import Dropzone from "react-dropzone";

function Quote() {
  return (
    <>
      <div className="Q">
        <div className="Q1">
          <h1>Get a Quote</h1>
          <p>Get inspired from some of our happy clients showing off their custom apparel.</p>
        </div>
        <div className="Q2">
          <div className="Q2a">
            <form action="submit">
              <div>
                <p>Name* </p>
                <input type="text" />
              </div>              
              <p>Company*</p>
              <input type="text" />
              <p>Email*</p>
              <input type="text" />
              <p>Phone Number*</p>
              <input type="text" />
              <p>Description*</p>
              <textarea type="text" />
              <p>Company*</p>
              <input type="file" />
              <button>Submit</button>
            </form>
          </div>
          <div className="Q2b">
            <span className="Q2ba">Contact Us</span> <br/>
            <p className="Q2bb">With cutting edge technologies we strive to create a long lasting impression with our high resolution quality.</p>
            <div className="Q2c">
              <div className="Q2c1">
                <img src={phone} alt="Phone" />
                <span>+254 722 611 475</span>
              </div>
              <div className="Q2c1">
                <img src={email} alt="Phone" />
                <span>info@pointone.co.ke</span>
              </div>
            </div>
            <img className="Q-img" src={contact} alt="Contact " />
          </div>
        </div>
      </div>
    </>
  );
}

export default Quote;