import React from "react";
import Navbar from "./Navbar";

function Home() {
  return (
    <>
      <div className="body">
        <Navbar />
        <div className="slogan">
          <span className="slogan1">
            Our Business is to Make Yours Stand Out.
          </span>
          <span className="slogan2">
            Explore the depth of our expertise and creativity by immersing
            yourself in our extensive collection of work.
          </span>
          <button>Explore More...</button>
        </div>
      </div>
    </>
  );
}

export default Home;
