import React from "react";
import digital from "../utilities/Ricoh.png";
import image1 from "../utilities/digital1.png";
import image2 from "../utilities/image2.png";
import image3 from "../utilities/image3.png";
import Quote from "./Quote";
import ContactUs from "./ContactUs";

function Digital() {
  return (
    <>
      <div className="D">
        <div className="D1">
          <img src={digital} alt="printer" />
        </div>
        <div className="D2">
          <div className="d2a">
            <h1>
              What is <br /> <span>Digital Printing?</span>
            </h1>
            <p>
              Digital printing is the process of printing digital-based images
              directly onto a variety of media substrates. There is no need for
              a printing plate, unlike with offset printing.
            </p>
          </div>
          <div className="d2b">
            <img src={image1} alt="a man thinking" />
          </div>
        </div>
        <div className="D2">
          <div className="d2b">
            <img src={image2} alt="a man thinking" />
          </div>
          <div className="d2a">
            <h1>
                What are the advantages of <br /> <span>Digital Printing?</span>
            </h1>
            <ul>
                <li>Full color. Various design possibilities with full color and gradation print without the limitation of colors.</li>
                <li>Quick delivery.</li>
                <li>Color matching time is reduced.</li>
                <li>Low cost. </li>
                <li>Simple. </li>
                <li>Small volume production. </li>
                <li>High added value. </li>
                <li>Environmentally friendly. </li>
            </ul>
          </div>
        </div>
        <div className="D2">
          <div className="d2a">
            <h1>
                Some of the outputs of <br /> <span>Digital Printing?</span>
            </h1>
            <ul>
                <li>Booklet Printing</li>
                <li>Business Cards</li>
                <li>Brochure Printing</li>
                <li>Folded Leaflets </li>
                <li>Posters </li>
                <li>Greeting Cards</li>
                <li>Labels </li>
                <li>Leaflets and Flyers</li>
                <li>Magazines </li>
            </ul>
          </div>
          <div className="d2b">
            <img src={image3} alt="a man thinking" />
          </div>
        </div>
        <Quote/>
        <ContactUs/>
      </div>
    </>
  );
}

export default Digital;
