import React from "react";
import logo from "../utilities/point-one-media.png";
import glossySticker from "../utilities/sticker.png";

function LargeFormatN() {
  return (
    <>
      <main className="largeFormatMain">
        <section className="lf-section1">
          <div className="LF-nav">
            <div>
              <img src={logo} alt="Point One Media Logo" />
            </div>
            <div>
              <ul>
                <li>Home</li>
                <li>Services</li>
                <li>Our Work</li>
              </ul>
            </div>
            <div>
              <p>Contact Us</p>
            </div>
          </div>
          <div className="lf-about">
            <h1>Large Format</h1>
            <span>Printing Services </span>
            <p>
              At Point One Media, we specialize in large format printing,
              offering high-quality, vibrant prints that make a big impact.
              Whether you need eye-catching banners, posters, signs, or
              displays, our state-of-the-art printing technology ensures
              stunning results every time.
            </p>
          </div>
        </section>
        <section className="lf-section2">
          <div>
            <ul>
              <li>Advertising Flags</li>
              <li>Artist Prints</li>
              <li>Backdrop Stands</li>
              <li>Banner Stands</li>
              <li>Billboard Skins</li>
              <li>Building Wraps</li>
              <li>Construction Site Hoardings</li>
              <li>Corporate Branding</li>
              <li>Directional Signage</li>
              <li>Exhibition Graphics</li>
              <li>Floor Mats</li>
              <li>Flags</li>
              <li>Giclée Prints</li>
              <li>Hoarding Panels</li>
              <li>In-store Displays</li>
              <li>Menu Boards</li>
              <li>Museum Displays</li>
              <li>Pop-up Displays</li>
              <li>Roll-up Banners</li>
              <li>Stage Graphics</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Architectural Renderings</li>
              <li>Backlit Displays</li>
              <li>Banners</li>
              <li>Billboards</li>
              <li>Blueprints</li>
              <li>Canvas Prints</li>
              <li>Custom Wallpaper</li>
              <li>Educational Charts</li>
              <li>Event Backdrops</li>
              <li>Floor Graphics</li>
              <li>Maps</li>
              <li>Photographic Prints</li>
              <li>Posters</li>
              <li>Presentation Boards</li>
              <li>Retail Displays</li>
              <li>Signs</li>
              <li>Trade Show Graphics</li>
              <li>Vehicle Wraps</li>
              <li>Wall Murals</li>
              <li>Window Graphics</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Backdrops for Video Productions</li>
              <li>Billboard Posters</li>
              <li>Building Floor Plans</li>
              <li>Ceiling Tiles</li>
              <li>Construction Barriers</li>
              <li>Cut Vinyl Decals</li>
              <li>Exhibition Stands</li>
              <li>Fabric Banners</li>
              <li>Foam Board Prints</li>
              <li>Hanging Banners</li>
              <li>Hotel and Restaurant Signage</li>
              <li>Illuminated Signs</li>
              <li>Interior Design Panels</li>
              <li>Mall Directory Maps</li>
              <li>Mesh Banners</li>
              <li>Parade Floats Graphics</li>
              <li>Point of Purchase Displays</li>
              <li>Reflective Signs</li>
              <li>Safety Posters</li>
              <li>Vinyl Stickers</li>
            </ul>
          </div>
        </section>
        <section className="lf-section3">
          <div className="lf-section3a">
            <h1>Why your business needs Large Format Printing</h1>
            <p className="lf-info">
              Your company could benefit from a large format printing if you do
              a lot of marketing printing, use digital printing, or are in a
              sector that often prints larger items. No matter your line of
              work, large format printing may help get your message out
              effectively. </p><p className="lf-info">
              Large scale printing may make a significant effect in
              a wide variety of settings, from stores and malls to offices and
              factories. It’s easy to locate a solution that works for your
              finances and your goals because it can be tailored to meet any
              requirement. Large format printing is a great way to create an
              impact with banner stands, posters, and trade show displays.
            </p>
          </div>
          <div>
            <img src={glossySticker} alt="Glossy Sticker" />
          </div>
        </section>
        <section className="lf-section4">
          <div>
            <button>Get a quote</button>
          </div>
          <div>
            <img src="" alt="" />
          </div>
          <div>
            <img src="" alt="" />
          </div>
          <div>
            <img src="" alt="" />
          </div>
        </section>
        <section>{/* footer */}</section>
      </main>
    </>
  );
}

export default LargeFormatN;
