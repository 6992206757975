import React, { useState } from "react";
// import Dropzone from "react-dropzone";

function Quote() {
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phone: "",
    description: "",
    artwork: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleFileDrop = (files) => {
  //   const file = files[0]; // Assuming only one file is dropped
  //   setFormData({ ...formData, artwork: file });
  //   // Preview file here if needed
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission, including file upload

    // Reset form data
    setFormData({
      fullName: "",
      companyName: "",
      email: "",
      phone: "",
      description: "",
      artwork: null,
    });
  };

  return (
    <>
      <main className="YY">
        <div className="">
          <div className="YY">
            <h1 className="">Get a quote</h1>
            <p className="">
              Use a permanent address where you can receive mail.
            </p>

            <form className="Y" onSubmit={handleSubmit}>
              <div className="y1">
                <label htmlFor="full-name" className="">
                  Full Names
                </label>
                <div className="">
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    
                  />
                </div>
              </div>

              <div className="y2">
                <label htmlFor="" className="">
                  Company name
                </label>
                <div className="">
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    
                  />
                </div>
              </div>

              <div className="y3">
                <label htmlFor="email" className="">
                  Email address
                </label>
                <div className="">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    
                  />
                </div>
              </div>

              <div className="y4">
                <label htmlFor="phone number" className="">
                  Phone Number
                </label>
                <div className="">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                                    />
                </div>
              </div>

              <div className="y5">
                <label htmlFor="company-name" className="">
                  Description
                </label>
                <div className="">
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    
                  />
                </div>
              </div>

              <div className="y6">
                <label htmlFor="cover-photo" className="">
                  Upload artwork
                </label>
                <div className="y6a">
                  {/* <Dropzone onDrop={handleFileDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                    )}
                  </Dropzone> */}
                  {formData.artwork && (
                    <div>
                      <p>Preview:</p>
                      <img
                        src={URL.createObjectURL(formData.artwork)}
                        alt="Preview"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="y7">
                <button className="y7a">Cancel</button>
                <button className="y7b" type="submit">
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
}

export default Quote;
