import React from "react";
import logo from "../utilities/point-one-media.png";
import quotes from "../utilities/quotes.svg";

function Footer1() {
  return (
    <>
      <div className="f-bg">
        <div className="f-main">
          <div className="f-section1">
            <div className="f-logo">
              <img src={logo} alt="company Logo" />
            </div>
            <div className="f-quote">
              <span>Where Your Ideas Come to Life in Print</span>
            </div>
            <div className="f-quotes">
              <img src={quotes} alt="Quotation Marks" />
            </div>
          </div>
          <div className="f-section2">
            <div>
              <ul>
                <li>
                  <span>Graphic design</span>
                </li>
                <li>Logo design</li>
                <li>Poster design</li>
                <li>Business Card design</li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <span>Branding</span>
                </li>
                <li>Company branding</li>
                <li>Vehicle branding</li>
                <li>Office branding</li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <span>Printing</span>
                </li>
                <li>Business cards</li>
                <li>Banners</li>
                <li>Stickers</li>
              </ul>
            </div>
          </div>
          <div className="f-section3">
            <div className="f-section3a">
              <span>
                Copyright © POINT ONE MEDIA ™ 2024. All rights reserved
              </span>
            </div>
            <div className="f-section3b">
              <form action="submit">
                <input
                  type="text"
                  name="newsletter"
                  id="newsletter"
                  placeholder="Your email here..."
                />
                <button>Update me</button>
              </form>
              <span>Subscribe to get the latest offers.</span>
            </div>
          </div>
        </div>
        </div>
    </>
  );
}

export default Footer1;
