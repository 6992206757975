import React from "react";

import img6 from "../utilities/img6.jpg";
import img1 from "../utilities/img1.jpeg";
import img2 from "../utilities/img2.jpg";
import img4 from "../utilities/img4.jpeg";
import img7 from "../utilities/img7.jpeg";
import img5 from "../utilities/img5.jpeg";
import img8 from "../utilities/img8.jpg";
import img9 from "../utilities/img9.jpg";
import img3 from "../utilities/img3.jpg";
import img10 from "../utilities/img10.jpg";


function OurWork(){
    return (
        <>
        <main className="ourwork">
            <h1>Our Work</h1>
            <p>Get inspired from some of our happy clients showing off their custom apparel.</p>
            <div className="grid-container" id="cont">
                <div className="grid-item1"><img src={img3} alt="Engraved Sign" /></div>
                <div className="grid-item2"><img src={img2} alt="Engraved Sign" /></div>
                <div className="grid-item3"><img src={img8} alt="Engraved Sign" /></div>
                <div className="grid-item4"><img src={img5} alt="Engraved Sign" /></div>
                <div className="grid-item5"><img src={img9} alt="Engraved Sign" /></div>
                <div className="grid-item6"><img src={img1} alt="Engraved Sign" /></div>
                <div className="grid-item7"><img src={img4} alt="Engraved Sign" /></div>
                <div className="grid-item8"><img src={img6} alt="Engraved Sign" /></div>
                <div className="grid-item9"><img src={img7} alt="Engraved Sign" /></div>
            </div>
            <div className="m-ourwork">
            <div className="grid-container">
                <div className="grid-item1"><img src={img2} alt="Engraved Sign" /></div>
                <div className="grid-item2"><img src={img2} alt="Engraved Sign" /></div>
                <div className="grid-item3"><img src={img9} alt="Engraved Sign" /></div>
                <div className="grid-item4"><img src={img10} alt="Engraved Sign" /></div>
                <div className="grid-item5"><img src={img9} alt="Engraved Sign" /></div>
                <div className="grid-item6"><img src={img1} alt="Engraved Sign" /></div>
                <div className="grid-item7"><img src={img4} alt="Engraved Sign" /></div>
                <div className="grid-item8"><img src={img6} alt="Engraved Sign" /></div>
                <div className="grid-item9"><img src={img7} alt="Engraved Sign" /></div>
            </div>
            </div>
            <button>Explore More...</button>
        </main>
        </>
    );
};


export default OurWork;