import "./App.css";
import React from "react";
import Banner from "./components/Banner";
import Category from "./components/Category";
import Clients from "./components/Clients";
import ContactUs from "./components/ContactUs";
import Digital from "./components/Digital";
// import Footer from "./components/Footer";
import Home from "./components/Home";
// import LargeFormat from "./components/LargeFormat";
import OurWork from "./components/OurWork";
import GetQuote from "./components/GetQuote";
import Services from "./components/Services";
import Stats from "./components/Stats";
import LargeFormatN from "./components/LargeFormatN";
// import Quote from "./components/Quote";
import Footer1 from "./components/Footer1";
// import { Switch, Route } from "react-router-dom/cjs/react-router-dom.min";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyBHwfegUG1PZxtLt9Gsd60pNH9f3h-nw4k",
//   authDomain: "point-one-media.firebaseapp.com",
//   projectId: "point-one-media",
//   storageBucket: "point-one-media.appspot.com",
//   messagingSenderId: "936868219648",
//   appId: "1:936868219648:web:18ab8f48b93f31fda501e5",
//   measurementId: "G-ZK3DKVW4WC"
// };


// Initialize Firebase
// const app = initializeApp(firebaseConfig);



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={
            <>
              <Home />
              <Stats />
              <Category />
              <Services />
              <Banner />
              <OurWork />
              <Clients />
              <GetQuote />
              <ContactUs />
              <Footer1/>
            </>
          } />
          <Route path="/digital" element={<Digital />} />
          <Route path="/largeformat" element={ <LargeFormatN/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
