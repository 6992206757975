import React from "react";

function Banner(){
    return(
        <>
        <div className="banner">
            <span>Your Brand Deserves the Best. Start Designing Now</span>
            <button>Start Designing</button>
        </div>
        </>
    );
};


export default Banner;