import React from "react";
import { useState } from "react";
import logo from "../utilities/point-one-media.png";
import search from "../utilities/Vector.svg";
// import MobileNav from "./MobileNav";
import close from "../utilities/vector13.svg";
import open from "../utilities/vector14.svg";
import { Link } from "react-scroll";

function Navbar() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => setIsMenuOpen(!isMenuOpen);


  return (
    <>
    {/* {isMenuOpen && <MobileNav isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>} */}
        <nav>

          {/* Point One Media Logo */}
          <div className="nav__logo">
            <img  src={logo} alt="Point one media logo" />
          </div>

          {/* The Menu */}
          <div class={ isMenuOpen ? "menu__open" : "menu__close"} id="menu">
            <img className="close" src={close} alt="close" onClick={handleMenu}/>
            <ul>
              <li>Home</li>
              <li>About</li>
              <li><Link to="services" smooth spy offset={-80} >Services</Link></li>
              <li><Link to="ourwork" smooth spy offset={-80} >Our work</Link></li>
              <li><Link to="get-a-quote" smooth spy offset={-80} >Get a Quote</Link></li>
            </ul>
          </div>

          {/* Search and Contact Buttons */}
          <div className="nav__buttons">
            <img className="search" src={search} alt="search" />
            <span className="contact"><Link to="Contact" smooth spy offset={-80} >Contact Us</Link></span>
          </div>

          {/* Open and close Buttons */}
          <div>
            <img className="open" src={open} alt="open" onClick={handleMenu} />
          </div>


        </nav>
    </>
  );
}

export default Navbar;
