import React from "react";
import logo from "../utilities/vector15.svg";

function Clients() {
  return (
    <>
      <main className="clients">
        <h1>What Our Clients Think</h1>
        <p>
          Get inspired from some of our happy clients showing off their custom
          apparel.
        </p>
        <div className="ZZ">
          <div className="Z">
            <div id="z1">
            "Exceptional service and top-notch quality! Point One Media is our go-to for all our printing needs."
            </div>
            <div className="z2">
                <div id="z2a">
                    <img src={logo} alt="Point one logo"/>
                </div>
                <div id="z2b">
                    <span id="z2b1">Elvis Kamau</span>
                    {/* <span id="z2b2">Founder Point One Media</span> */}
                </div>
            </div>
          </div>
          <div className="Z">
            <div id="z1">
            "The team at Point One Media went above and beyond to deliver perfect prints on a tight deadline."
            </div>
            <div className="z2">
                <div id="z2a">
                    <img src={logo} alt="Point one logo"/>
                </div>
                <div id="z2b">
                    <span id="z2b1">Miriam Achieng</span>
                    {/* <span id="z2b2">Founder of Point One Media</span> */}
                </div>
            </div>
          </div>
          <div className="Z" id="Z">
            <div id="z1">
            "Highly recommend! The attention to detail and customer service are outstanding."
            </div>
            <div className="z2" >
                <div id="z2a">
                    <img src={logo} alt="Point one logo"/>
                </div>
                <div id="z2b">
                    <span id="z2b1">Adrian Ng'eno</span>
                    {/* <span id="z2b2">Founder of Point One Media</span> */}
                </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Clients;
